<template>
 <div id="page-container" class="pb-5">
  <div class="content-wrap">
   <PbxDashboard
    v-model:selector="periodSelector"
    @action="
     selected_answered_type === 'day'
      ? getDataFromElementForColumnDaily()
      : selected_answered_type === 'week'
      ? getDataFromElementForColumnWeek()
      : selected_answered_type === 'weekNumber'
      ? getDataFromElementForColumnWeekNumber()
      : selected_answered_type === 'hour'
      ? getDataFromElementForColumn()
      : getDataFromElementForColumnMonth()
    "
    @setSelector="setSelector = $event"
    @all-selected="allSelected($event)"
    @button-selector="(...args) => getSelectedButton(...args)"
    @timeSelector="(isQueryParam = false), setTimeSelected($event)"
    :buttonSelected="this.buttonSelected"
    :extensions="extensions"
    :dataColumnChartQuarterHourlyCons="
     selected_answered_type === 'day'
      ? dataColumnChartCons
      : selected_answered_type === 'week'
      ? dataColumnChartConsWeek
      : selected_answered_type === 'weekNumber'
      ? dataColumnChartConsWeekNumber
      : selected_answered_type === 'hour'
      ? dataColumnChartQuarterHourlyCons
      : dataColumnChartConsMonth
    "
    :dataColumnChartQuarterHourly="
     is_filtered
      ? selected_answered_type === 'day'
        ? filterNoData(dataColumnChart)
        : selected_answered_type === 'week'
        ? filterNoData(dataColumnChartWeek)
        : selected_answered_type === 'weekNumber'
        ? filterNoData(dataColumnChartWeekNumber)
        : selected_answered_type === 'hour'
        ? filterNoData(dataColumnChartQuarterHourly)
        : filterNoData(dataColumnChartMonth)
      : selected_answered_type === 'day'
      ? dataColumnChart
      : selected_answered_type === 'week'
      ? dataColumnChartWeek
      : selected_answered_type === 'weekNumber'
      ? dataColumnChartWeekNumber
      : selected_answered_type === 'hour'
      ? dataColumnChartQuarterHourly
      : dataColumnChartMonth
    "
    :dataColumnChartHourly="
     is_daily ? dataColumnChartCons : dataColumnChartHourly
    "
    :periodSelector="this.periodSelector"
    :timeSelected="this.timeSelected"
    :textMonth="this.textMonth"
    :urlType="urlType"
    :queryElement="queryElement"
    @concatenate="concatenate = $event"
    @setPeriod="(...args) => setPeriod(...args)"
    @is_filtered="is_filtered = $event"
    @selected_answered_type="selected_answered_type = $event"
    :startDate="startDate"
    :endDate="endDate"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
    @setLoading="$emit('setLoading', $event)" />
  </div>
  <!-- <div v-show="extensions.length === 0"><EmptyStateDashboard /></div> -->
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage" />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];

export default {
 props: ["urlType"],
 components: {
  EmptyStateDashboard,
  Loading,
  PbxDashboard,
 },
 data() {
  return {
   daysOfWeek,
   selected_answered_type: "hour",
   is_filtered: true,
   is_daily: true,
   concatenate: false,
   queryElement: [],
   setSelector: undefined,
   isQueryParam: false,
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   dataColumnChartQuarterHourly: [],
   dataColumnChartQuarterHourlyCons: [],
   dataColumnChartHourly: [],
   dataColumnChartHourlyCons: [],
   dataColumnChart: [],
   dataColumnChartWeek: [],
   dataColumnChartWeekNumber: [],
   dataColumnChartMonth: [],
   dataColumnChartCons: [],
   dataColumnChartConsWeek: [],
   dataColumnChartConsWeekNumber: [],
   dataColumnChartConsMonth: [],
   extensions: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: "month",
   textMonth: "",
   data: undefined,
   startDate: null,
   endDate: null,
  };
 },
 methods: {
  setPeriod(...args) {
   this.startDate = args[0];
   this.endDate = args[1];
   this.dataColumnChartQuarterHourly = [];
   this.dataColumnChartQuarterHourlyCons = [];
   this.dataColumnChartHourly = [];
   this.dataColumnChartHourlyCons = [];
   this.dataColumnChart = [];
   this.dataColumnChartWeek = [];
   this.dataColumnChartWeekNumber = [];
   this.dataColumnChartMonth = [];
   this.dataColumnChartCons = [];
   this.dataColumnChartConsWeek = [];
   this.dataColumnChartConsMonth = [];
   switch (this.selected_answered_type) {
    case "hour":
     this.getDataFromElementForColumn();
    case "day":
     this.getDataFromElementForColumnDaily();
    case "week":
     this.getDataFromElementForColumnWeek();
    case "weekNumber":
     this.getDataFromElementForColumnWeekNumber();
    case "month":
     this.getDataFromElementForColumnMonth();
    default:
     return "";
   }
   //  this.is_daily
   //   ? this.getDataFromElementForColumnDaily()
   //   : this.getDataFromElementForColumn();
  },
  getButtonSelectedFromQueryPeriod() {
   let period = undefined;
   if (this.$route.query.period) {
    period = this.$route.query.period;
   }
   if (period) {
    if (period.length == 8) {
     this.buttonSelected = "day";
    } else if (period.length == 6) {
     this.buttonSelected = "month";
    } else {
     this.buttonSelected = "week";
    }
   }
  },
  async getPbxData(stringOfElements, sentPeriod, type) {
   if (this.startDate && this.endDate) {
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
     this.hostName
    }/answered?start=${this.startDate}&end=${
     this.endDate
    }&dns=${stringOfElements}&dn_type=${this.urlType === "queue" ? "4" : "0"}`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     console.log(
      "file: ExpertStatistics.vue:99 ~ getData ~ res.data:",
      res.data
     );
     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getDataFromElementForColumnDaily(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;

   this.dataColumnChart = [];
   this.dataColumnChartCons = [];

   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";

   this.extensions.length = 0;

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements);
     this.data = res;
    }

    let keys = [];
    let keysConc = [];

    if (this.timeSelected.length > 0) {
     if (
      this.timeSelected[0].slice(-2) === "00" &&
      this.timeSelected[1].slice(-2) === "00"
     ) {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
     let startTimeSelected = this.timeSelected[0];
     let endTimeSelected = this.timeSelected[1];
    }

    keys = Object.keys(res.daily);
    keysConc = Object.keys(res["daily*"]);

    let days = [];
    days = res.days;

    let formatedDays = [];

    days.forEach(day => {
     formatedDays.push(this.formatDateApiRequest(day));
    });

    if (keys.length > 0) {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      dataObjUn = {};
      dataObjUnCons = {};
      dataObjAns = {};
      dataObjAnsCons = {};
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"] = [];
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"] = [];
      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"] = [];
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"] = [];
      obj["data"] = [];
      objCons["data"] = [];
      obj["labels"] = [];
      objCons["labels"] = [];

      let filledArrayDataAns = new Array(days.length).fill(0);
      let filledArrayDataUns = new Array(days.length).fill(0);
      let filledArrayDataAnsCons = new Array(days.length).fill(0);
      let filledArrayDataUnsCons = new Array(days.length).fill(0);
      let filledArrayDataAnsHourly = [];
      let filledArrayDataAnsHourlyCons = [];
      let filledArrayDataUnsHourly = [];
      let filledArrayDataUnsHourlyCons = [];

      keys.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0];
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] == arrayOfElements[arrIndex]) {
        obj["name"] = splitElement[2] + " - " + elementName;
        if (splitElement[3] === "answered") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataAns.splice(
            index,
            1,
            parseInt(res.daily[element]) <=
             parseInt(res["daily"][element.replace("answered", "calls")])
             ? parseInt(res.daily[element])
             : parseInt(res["daily"][element.replace("answered", "calls")])
           );
          }
         }
         dataObjAns["name"] = "Répondus";
         dataObjAns["data"] = filledArrayDataAns;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataUns.splice(
            index,
            1,
            parseInt(res["daily"][element]) -
             (parseInt(res["daily"][element.replace("calls", "answered")])
              ? parseInt(res["daily"][element.replace("calls", "answered")]) <=
                parseInt(res["daily"][element])
                ? parseInt(res["daily"][element.replace("calls", "answered")])
                : parseInt(res["daily"][element])
              : 0)
           );
          }
         }
         dataObjUn["name"] = "Non répondus";
         dataObjUn["data"] = filledArrayDataUns;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysConc.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0];
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataAnsCons.splice(
            index,
            1,
            parseInt(res["daily*"][element]) <=
             parseInt(res["daily*"][element.replace("answered", "calls")])
             ? parseInt(res["daily*"][element])
             : parseInt(res["daily*"][element.replace("answered", "calls")])
           );
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(res["daily*"][element]) -
             (parseInt(res["daily*"][element.replace("calls", "answered")])
              ? parseInt(res["daily*"][element.replace("calls", "answered")]) <=
                parseInt(res["daily*"][element])
                ? parseInt(res["daily*"][element.replace("calls", "answered")])
                : parseInt(res["daily*"][element])
              : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      obj["labels"] = days;
      objCons["labels"] = days;

      obj["data"].splice(0, 1, dataObjAns);
      obj["data"].splice(1, 1, dataObjUn);

      objCons["data"].splice(0, 1, dataObjAnsCons);
      objCons["data"].splice(1, 1, dataObjUnCons);

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChart.splice(arrIndex, 1, obj);
      this.dataColumnChartCons.splice(0, 1, objCons);
     }
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      let elementName = "";
      elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];

      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["name"] = "cons";
      obj["labels"] = [];
      objCons["labels"] = [];
      obj["labels"] = [];
      obj["data"] = [];

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChart.splice(arrIndex, 1, obj);
      this.dataColumnChartCons.splice(0, 1, objCons);
     }
    }
   }
   this.$emit("setLoading", false);
  },
  async getDataFromElementForColumn(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let uniqueTimeLabel = ["0600", "2000"];

   let res = undefined;

   this.dataColumnChartQuarterHourly = [];
   this.dataColumnChartQuarterHourlyCons = [];
   this.dataColumnChartHourly = [];
   this.dataColumnChartHourlyCons = [];
   let period = this.currentMonthAndYearForPbx;
   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";
   this.extensions.length = 0;

   if (this.setSelector) {
    period = this.setSelector;
   }

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let objHourly = {};
    let objHourlyCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjAnsHourly = {};
    let dataObjAnsHourlyCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};
    let dataObjUnHourly = {};
    let dataObjUnHourlyCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements, period, type);
     this.data = res;
    }

    this.dataColumnChartQuarterHourly = [];
    this.dataColumnChartQuarterHourlyCons = [];
    this.dataColumnChartHourly = [];
    this.dataColumnChartHourlyCons = [];
    let keys = [];
    let keysConc = [];
    let keysHourly = [];
    let keysHourlyConc = [];
    let timeSelectedIsHour = false;
    let timeSelectedIsQuarter = false;
    let startTimeSelected = undefined;
    let endTimeSelected = undefined;

    if (this.timeSelected.length > 0) {
     if (
      this.timeSelected[0].slice(-2) === "00" &&
      this.timeSelected[1].slice(-2) === "00"
     ) {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
     startTimeSelected = this.timeSelected[0];
     endTimeSelected = this.timeSelected[1];
    }

    keys = Object.keys(res.sum_quarter_hourly);
    keysConc = Object.keys(res["sum_quarter_hourly*"]);
    keysHourly = Object.keys(res.sum_hourly);
    keysHourlyConc = Object.keys(res["sum_hourly*"]);

    let timeLabelRange = [];
    for (let index = 0; index < keysHourly.length; index++) {
     const element = keysHourly[index];
     if (element.split(":")[0].length == 10) {
      let time = element.split(":")[0].slice(-2);
      if (timeLabelRange.length) {
       if (!timeLabelRange[1]) {
        timeLabelRange.splice(1, 1, time);
       }
       if (parseInt(time) < parseInt(timeLabelRange[0])) {
        timeLabelRange.splice(0, 1, time);
       }
       if (parseInt(time) > parseInt(timeLabelRange[1])) {
        timeLabelRange.splice(1, 1, time);
       }
      } else {
       timeLabelRange.push(time);
      }
     }
    }
    let timeLabelStart =
     parseInt(timeLabelRange[0]) + parseInt(this.hostTimeZone) + "00";
    let timeLabelEnd =
     parseInt(timeLabelRange[1]) + 1 + parseInt(this.hostTimeZone) + "00";
    uniqueTimeLabel = [timeLabelStart, timeLabelEnd];

    if (keys.length > 0 || keysHourly.length > 0) {
     let timeLabels = [];
     let timeLabelsCons = [];
     let timeLabelsHourly = [];
     let timeLabelsHourlyCons = [];

     if (timeSelectedIsHour || timeSelectedIsQuarter) {
      timeLabels = await this.getTimeLabelRangeStartAndEnd(
       startTimeSelected,
       endTimeSelected
      );
      timeLabelsCons = timeLabels;
      timeLabelsHourly = await this.getTimeLabelRangeHourlyStartAndEnd(
       startTimeSelected,
       endTimeSelected
      );
      timeLabelsHourlyCons = timeLabelsHourly;
     } else {
      timeLabels = await this.getTimeLabelRangeStartAndEnd(
       uniqueTimeLabel[0],
       uniqueTimeLabel[1]
      );
      timeLabelsCons = timeLabels;
      timeLabelsHourly = await this.getTimeLabelRangeHourlyStartAndEnd(
       uniqueTimeLabel[0],
       uniqueTimeLabel[1]
      );
      timeLabelsHourlyCons = timeLabelsHourly;
     }
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      objHourly = {};
      objHourlyCons = {};
      dataObjUnHourly = {};
      dataObjUnHourlyCons = {};
      dataObjUn = {};
      dataObjUnCons = {};
      dataObjAnsHourly = {};
      dataObjAnsHourlyCons = {};
      dataObjAns = {};
      dataObjAnsCons = {};
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"] = [];
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"] = [];
      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"] = [];
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"] = [];
      obj["data"] = [];
      objCons["data"] = [];
      objHourly["data"] = [];
      objHourlyCons["data"] = [];
      obj["labels"] = [];
      objCons["labels"] = [];
      objHourly["labels"] = [];
      objHourlyCons["labels"] = [];

      let filledArrayDataAns = [];
      let filledArrayDataUns = [];
      let filledArrayDataAnsCons = [];
      let filledArrayDataUnsCons = [];
      let filledArrayDataAnsHourly = [];
      let filledArrayDataAnsHourlyCons = [];
      let filledArrayDataUnsHourly = [];
      let filledArrayDataUnsHourlyCons = [];

      filledArrayDataAns = new Array(timeLabels.length).fill(0);
      filledArrayDataAnsCons = new Array(timeLabelsCons.length).fill(0);
      filledArrayDataAnsHourly = new Array(timeLabelsHourly.length).fill(0);
      filledArrayDataUnsHourly = new Array(timeLabelsHourly.length).fill(0);
      filledArrayDataAnsHourlyCons = new Array(
       timeLabelsHourlyCons.length
      ).fill(0);
      filledArrayDataUns = new Array(timeLabels.length).fill(0);
      filledArrayDataUnsCons = new Array(timeLabelsCons.length).fill(0);
      filledArrayDataUnsHourlyCons = new Array(
       timeLabelsHourlyCons.length
      ).fill(0);

      keys.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let splitedHour = splitElement[0].slice(-4);
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (
        splitElement[2] == arrayOfElements[arrIndex] &&
        splitElement[0].length == 12
       ) {
        obj["name"] = splitElement[2] + " - " + elementName;
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabels.length; index++) {
          const elementTest = timeLabels[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataAns.splice(
            index,
            1,
            parseInt(res.sum_quarter_hourly[element]) <=
             parseInt(
              res["sum_quarter_hourly"][element.replace("answered", "calls")]
             )
             ? parseInt(res.sum_quarter_hourly[element])
             : parseInt(
                res["sum_quarter_hourly"][element.replace("answered", "calls")]
               )
           );
          }
         }
         dataObjAns["name"] = "Répondus";
         dataObjAns["data"] = filledArrayDataAns;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabels.length; index++) {
          const elementTest = timeLabels[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataUns.splice(
            index,
            1,
            parseInt(res["sum_quarter_hourly"][element]) -
             (parseInt(
              res["sum_quarter_hourly"][element.replace("calls", "answered")]
             )
              ? parseInt(
                 res["sum_quarter_hourly"][element.replace("calls", "answered")]
                ) <= parseInt(res["sum_quarter_hourly"][element])
                ? parseInt(
                   res["sum_quarter_hourly"][
                    element.replace("calls", "answered")
                   ]
                  )
                : parseInt(res["sum_quarter_hourly"][element])
              : 0)
           );
          }
         }
         dataObjUn["name"] = "Non répondus";
         dataObjUn["data"] = filledArrayDataUns;
        }
       } else if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataAnsCons.splice(
            index,
            1,
            parseInt(res.sum_quarter_hourly[element])
           );
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(res.sum_quarter_hourly[element]) -
             (parseInt(
              res.sum_quarter_hourly[element.replace("calls", "answered")]
             )
              ? parseInt(
                 res.sum_quarter_hourly[element.replace("calls", "answered")]
                )
              : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysHourly.forEach((element, index) => {
       let splitElement = element.split(":");
       let splitedHour = splitElement[0].slice(-2);
       if (
        splitElement[2] == arrayOfElements[arrIndex] &&
        splitElement[0].length == 10
       ) {
        objHourly["name"] = splitElement[2];
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsHourly.length; index++) {
          const elementTest = timeLabelsHourly[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataAnsHourly.splice(
            index,
            1,
            parseInt(res.sum_hourly[element]) <=
             parseInt(res["sum_hourly"][element.replace("answered", "calls")])
             ? parseInt(res.sum_hourly[element])
             : parseInt(res["sum_hourly"][element.replace("answered", "calls")])
           );
          }
         }
         dataObjAnsHourly["name"] = "Répondus";
         dataObjAnsHourly["data"] = filledArrayDataAnsHourly;
        } else if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsHourly.length; index++) {
          const elementTest = timeLabelsHourly[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataUnsHourly.splice(
            index,
            1,
            parseInt(res.sum_hourly[element]) -
             (parseInt(res.sum_hourly[element.replace("calls", "answered")])
              ? parseInt(res.sum_hourly[element.replace("calls", "answered")])
              : 0)
           );
          }
         }
         dataObjUnHourly["name"] = "Non répondus";
         dataObjUnHourly["data"] = filledArrayDataUnsHourly;
        }
       } else if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataAnsHourlyCons.splice(
            index,
            1,
            parseInt(res.sum_hourly[element])
           );
          }
         }
         dataObjAnsHourlyCons["name"] = "Répondus";
         dataObjAnsHourlyCons["data"] = filledArrayDataAnsHourlyCons;
         objHourlyCons["data"].splice(0, 1, dataObjAnsHourlyCons);
        } else if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataUnsHourlyCons.splice(
            index,
            1,
            parseInt(res.sum_hourly[element]) -
             (parseInt(res.sum_hourly[element.replace("calls", "answered")])
              ? parseInt(res.sum_hourly[element.replace("calls", "answered")])
              : 0)
           );
          }
         }
         dataObjUnHourlyCons["name"] = "Non répondus";
         dataObjUnHourlyCons["data"] = filledArrayDataUnsHourlyCons;
         objHourlyCons["data"].splice(1, 1, dataObjUnHourlyCons);
        }
       } else {
        // ! in case there is key but no data for element
        let elementName = "";
        elementName =
         this.urlType === "queue"
          ? res.map[arrayOfElements[arrIndex]]?.name
          : res.map[arrayOfElements[arrIndex]];
        objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysConc.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] === "*" && splitElement[0].length == 12) {
        let splitedHour = splitElement[0].slice(-4);
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataAnsCons.splice(
            index,
            1,
            parseInt(res["sum_quarter_hourly*"][element]) <=
             parseInt(
              res["sum_quarter_hourly*"][element.replace("answered", "calls")]
             )
             ? parseInt(res["sum_quarter_hourly*"][element])
             : parseInt(
                res["sum_quarter_hourly*"][element.replace("answered", "calls")]
               )
           );
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(res["sum_quarter_hourly*"][element]) -
             (parseInt(
              res["sum_quarter_hourly*"][element.replace("calls", "answered")]
             )
              ? parseInt(
                 res["sum_quarter_hourly*"][
                  element.replace("calls", "answered")
                 ]
                ) <= parseInt(res["sum_quarter_hourly*"][element])
                ? parseInt(
                   res["sum_quarter_hourly*"][
                    element.replace("calls", "answered")
                   ]
                  )
                : parseInt(res["sum_quarter_hourly*"][element])
              : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysHourlyConc.forEach((element, index) => {
       let splitElement = element.split(":");
       if (splitElement[2] === "*" && splitElement[0].length == 10) {
        let splitedHour = splitElement[0].slice(-2);
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataAnsHourlyCons.splice(
            index,
            1,
            parseInt(res["sum_hourly*"][element])
           );
          }
         }
         dataObjAnsHourlyCons["name"] = "Répondus";
         dataObjAnsHourlyCons["data"] = filledArrayDataAnsHourlyCons;
         objHourlyCons["data"].splice(0, 1, dataObjAnsHourlyCons);
        } else if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataUnsHourlyCons.splice(
            index,
            1,
            parseInt(res["sum_hourly*"][element]) -
             (parseInt(res["sum_hourly*"][element.replace("calls", "answered")])
              ? parseInt(
                 res["sum_hourly*"][element.replace("calls", "answered")]
                )
              : 0)
           );
          }
         }
         dataObjUnHourlyCons["name"] = "Non répondus";
         dataObjUnHourlyCons["data"] = filledArrayDataUnsHourlyCons;
         objHourlyCons["data"].splice(1, 1, dataObjUnHourlyCons);
        }
       } else {
        // ! in case there is key but no data for element
        let elementName = "";
        elementName =
         this.urlType === "queue"
          ? res.map[arrayOfElements[arrIndex]]?.name
          : res.map[arrayOfElements[arrIndex]];
        objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      obj["labels"] = timeLabels;
      objHourly["labels"] = timeLabelsHourly;
      objCons["labels"] = timeLabelsCons;
      objHourlyCons["labels"] = timeLabelsHourlyCons;

      obj["data"].splice(0, 1, dataObjAns);
      obj["data"].splice(1, 1, dataObjUn);

      objHourly["data"].splice(0, 1, dataObjAnsHourly);
      objHourly["data"].splice(1, 1, dataObjUnHourly);

      objCons["data"].splice(0, 1, dataObjAnsCons);
      objCons["data"].splice(1, 1, dataObjUnCons);

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartQuarterHourly.splice(arrIndex, 1, obj);
      this.dataColumnChartHourly.splice(arrIndex, 1, objHourly);
      this.dataColumnChartQuarterHourlyCons.splice(0, 1, objCons);
      this.dataColumnChartHourlyCons.splice(0, 1, objHourlyCons);
     }
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objHourly = {};
      objCons = {};
      let elementName = "";
      elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];

      objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["name"] = "cons";
      objHourlyCons["name"] = "cons";
      obj["labels"] = [];
      objHourly["labels"] = [];
      objCons["labels"] = [];
      objHourlyCons["labels"] = [];
      obj["labels"] = [];
      obj["data"] = [];

      objHourly["data"] = objCons["data"] = obj["data"];

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartQuarterHourly.splice(arrIndex, 1, obj);
      this.dataColumnChartHourly.splice(arrIndex, 1, objHourly);
      this.dataColumnChartQuarterHourlyCons.splice(0, 1, objCons);
      this.dataColumnChartHourlyCons.splice(0, 1, objHourlyCons);
     }
    }
   }
   this.$emit("setLoading", false);
  },
  async getDataFromElementForColumnWeek(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;

   this.dataColumnChartWeek = [];
   this.dataColumnChartConsWeek = [];

   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";

   this.extensions.length = 0;

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let objHourly = {};
    let objHourlyCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjAnsHourly = {};
    let dataObjAnsHourlyCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};
    let dataObjUnHourly = {};
    let dataObjUnHourlyCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements);
     this.data = res;
    }
    console.log("getDataFromElementForColumnWeek ~ res:", res);

    this.dataColumnChartWeek = [];
    this.dataColumnChartConsWeek = [];

    let keys = [];
    let keysHourly = [];
    let keysWeekDay = [];
    let keysWeekDayCons = [];
    let timeSelectedIsHour = false;
    let timeSelectedIsQuarter = false;
    let startTimeSelected = undefined;
    let endTimeSelected = undefined;

    if (this.timeSelected.length > 0) {
     if (
      this.timeSelected[0].slice(-2) === "00" &&
      this.timeSelected[1].slice(-2) === "00"
     ) {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
     startTimeSelected = this.timeSelected[0];
     endTimeSelected = this.timeSelected[1];
    }

    keysWeekDay = Object.keys(res.day_of_week);
    keysWeekDayCons = Object.keys(res["day_of_week*"]);
    let timeLabelRange = [];
    for (let index = 0; index < keysHourly.length; index++) {
     const element = keysHourly[index];
     let time = element.split(":")[2].slice(-2);
     if (timeLabelRange.length) {
      if (!timeLabelRange[1]) {
       timeLabelRange.splice(1, 1, time);
      }
      if (parseInt(time) < parseInt(timeLabelRange[0])) {
       timeLabelRange.splice(0, 1, time);
      }
      if (parseInt(time) > parseInt(timeLabelRange[1])) {
       timeLabelRange.splice(1, 1, time);
      }
     } else {
      timeLabelRange.push(time);
     }
    }

    if (keysWeekDay.length > 0) {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      dataObjUn = {};
      dataObjUnCons = {};
      dataObjAns = {};
      dataObjAnsCons = {};
      dataObjAns["name"] = "answered";
      dataObjAns["data"] = [];
      dataObjAnsCons["name"] = "answered";
      dataObjAnsCons["data"] = [];
      dataObjUn["name"] = "unanswered";
      dataObjUn["data"] = [];
      dataObjUnCons["name"] = "unanswered";
      dataObjUnCons["data"] = [];
      obj["data"] = [];
      objCons["data"] = [];
      obj["labels"] = [];
      objCons["labels"] = [];

      let filledArrayDataAns = [];
      let filledArrayDataUns = [];
      let filledArrayDataAnsCons = [];
      let filledArrayDataUnsCons = [];

      filledArrayDataAns = new Array(this.daysOfWeek.length).fill(0);
      filledArrayDataAnsCons = new Array(this.daysOfWeek.length).fill(0);
      filledArrayDataUns = new Array(this.daysOfWeek.length).fill(0);
      filledArrayDataUnsCons = new Array(this.daysOfWeek.length).fill(0);

      keysWeekDay.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0].slice(-3);
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] == arrayOfElements[arrIndex]) {
        obj["name"] = splitElement[2] + " - " + elementName;
        if (splitElement[3] === "answered") {
         for (let index = 0; index < this.daysOfWeek.length; index++) {
          const elementDay = this.daysOfWeek[index];
          if (elementDay === requestDay) {
           filledArrayDataAns.splice(
            index,
            1,
            parseInt(res.day_of_week[element]) <=
             parseInt(res["day_of_week"][element.replace("answered", "calls")])
             ? parseInt(res.day_of_week[element])
             : parseInt(
                res["day_of_week"][element.replace("answered", "calls")]
               )
           );
          }
         }
         dataObjAns["name"] = "Répondus";
         dataObjAns["data"] = filledArrayDataAns;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < this.daysOfWeek.length; index++) {
          const elementDay = this.daysOfWeek[index];
          if (elementDay === requestDay) {
           filledArrayDataUns.splice(
            index,
            1,
            parseInt(res["day_of_week"][element]) -
             (parseInt(res["day_of_week"][element.replace("calls", "answered")])
              ? parseInt(
                 res["day_of_week"][element.replace("calls", "answered")]
                ) <= parseInt(res["day_of_week"][element])
                ? parseInt(
                   res["day_of_week"][element.replace("calls", "answered")]
                  )
                : parseInt(res["day_of_week"][element])
              : 0)
           );
          }
         }
         dataObjUn["name"] = "Non répondus";
         dataObjUn["data"] = filledArrayDataUns;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysWeekDayCons.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0].slice(-3);
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < this.daysOfWeek.length; index++) {
          const elementDay = this.daysOfWeek[index];
          if (elementDay === requestDay) {
           filledArrayDataAnsCons.splice(
            index,
            1,
            parseInt(res["day_of_week*"][element]) <=
             parseInt(res["day_of_week*"][element.replace("answered", "calls")])
             ? parseInt(res["day_of_week*"][element])
             : parseInt(
                res["day_of_week*"][element.replace("answered", "calls")]
               )
           );
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < this.daysOfWeek.length; index++) {
          const elementDay = this.daysOfWeek[index];
          if (elementDay === requestDay) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(res["day_of_week*"][element]) -
             (parseInt(
              res["day_of_week*"][element.replace("calls", "answered")]
             )
              ? parseInt(
                 res["day_of_week*"][element.replace("calls", "answered")]
                ) <= parseInt(res["day_of_week*"][element])
                ? parseInt(
                   res["day_of_week*"][element.replace("calls", "answered")]
                  )
                : parseInt(res["day_of_week*"][element])
              : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });
      obj["labels"] = this.daysOfWeek;
      objCons["labels"] = this.daysOfWeek;

      obj["data"].splice(0, 1, dataObjAns);
      obj["data"].splice(1, 1, dataObjUn);

      objCons["data"].splice(0, 1, dataObjAnsCons);
      objCons["data"].splice(1, 1, dataObjUnCons);

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartWeek.splice(arrIndex, 1, obj);
      this.dataColumnChartConsWeek.splice(0, 1, objCons);
     }
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objHourly = {};
      objCons = {};
      let elementName = "";
      elementName = res.map[arrayOfElements[arrIndex]];

      objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["name"] = "cons";
      objHourlyCons["name"] = "cons";
      obj["labels"] = [];
      objHourly["labels"] = [];
      objCons["labels"] = [];
      objHourlyCons["labels"] = [];
      obj["labels"] = [];
      obj["data"] = [];

      objHourly["data"] = objCons["data"] = obj["data"];

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartWeek.splice(arrIndex, 1, obj);
      this.dataColumnChartConsWeek.splice(0, 1, objCons);
     }
    }
   }
   this.$emit("setLoading", false);
  },
  async getDataFromElementForColumnMonth(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;
   this.dataColumnChartMonth = [];
   this.dataColumnChartConsMonth = [];

   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";

   this.extensions.length = 0;

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements);
     this.data = res;
    }

    this.dataColumnChartMonth = [];
    this.dataColumnChartConsMonth = [];

    let keysMonth = [];
    let keysMonthCons = [];

    let groupedDataByMonth = {};
    let groupedDataByMonthCons = {};
    for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
     // Group the daily data into months
     Object.keys(res["daily"]).forEach(key => {
      let [date, , element, metric] = key.split(":");
      let month = date.slice(0, 6); // Extract the month from the date (YYYYMM)
      if (element == arrayOfElements[arrIndex]) {
       if (!groupedDataByMonth[month]) {
        groupedDataByMonth[month] = { calls: 0, answered: 0 };
       }

       // Increment the relevant metric for this month
       if (metric === "calls") {
        groupedDataByMonth[month].calls += parseInt(res["daily"][key]);
       }

       if (metric === "answered") {
        groupedDataByMonth[month].answered += parseInt(res["daily"][key]);
       }
      }
     });

     Object.keys(res["daily*"]).forEach(key => {
      let [date, , , metric] = key.split(":");
      let month = date.slice(0, 6); // Extract the month from the date (YYYYMM)

      if (!groupedDataByMonthCons[month]) {
       groupedDataByMonthCons[month] = { calls: 0, answered: 0 };
      }

      // Increment the relevant metric for this month
      if (metric === "calls") {
       groupedDataByMonthCons[month].calls += parseInt(res["daily*"][key]);
      }

      if (metric === "answered") {
       groupedDataByMonthCons[month].answered += parseInt(res["daily*"][key]);
      }
     });

     // Prepare the data for the column chart
     let months = Object.keys(groupedDataByMonth);

     months.sort(); // Sort months in chronological order

     obj = {};
     objCons = {};
     dataObjAns = {};
     dataObjAnsCons = {};
     dataObjUn = {};
     dataObjUnCons = {};
     dataObjAns["data"] = [];
     dataObjUn["data"] = [];
     dataObjAnsCons["data"] = [];
     dataObjUnCons["data"] = [];
     obj["labels"] = [];
     objCons["labels"] = [];
     months.forEach((month, monthIdx) => {
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"].splice(
       monthIdx,
       1,
       groupedDataByMonth[month].answered
      );
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"].splice(
       monthIdx,
       1,
       groupedDataByMonthCons[month].answered
      );

      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"].splice(
       monthIdx,
       1,
       groupedDataByMonth[month].calls - groupedDataByMonth[month].answered
      );
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"].splice(
       monthIdx,
       1,
       groupedDataByMonthCons[month].calls -
        groupedDataByMonthCons[month].answered
      );

      let elementName = "";
      elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];

      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      obj["labels"].splice(monthIdx, 1, month);

      objCons["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["labels"].splice(monthIdx, 1, month);
     });
     obj["data"] = [dataObjAns, dataObjUn];
     objCons["data"] = [dataObjAnsCons, dataObjUnCons];
     this.extensions.splice(arrIndex, 1, obj["name"]);
     this.dataColumnChartMonth.splice(arrIndex, 1, obj);
     this.dataColumnChartConsMonth.splice(arrIndex, 1, objCons);
    }
    this.$emit("setLoading", false);
   }
  },
  async getDataFromElementForColumnWeekNumber(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;
   this.dataColumnChartWeekNumber = [];
   this.dataColumnChartConsWeekNumber = [];

   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";

   this.extensions.length = 0;

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements);
     this.data = res;
    }

    this.dataColumnChartWeekNumber = [];
    this.dataColumnChartConsWeekNumber = [];

    let groupedDataByWeek = {};
    let groupedDataByWeekCons = {};

    for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
     groupedDataByWeek = {};
     groupedDataByWeekCons = {};
     // Group the daily data into weeks
     Object.keys(res["daily"]).forEach(key => {
      let [date, , element, metric] = key.split(":");
      let week = this.getWeekNumber(date); // Extract the week number from the date (YYYYMMDD)
      if (element == arrayOfElements[arrIndex]) {
       if (!groupedDataByWeek[week]) {
        groupedDataByWeek[week] = { calls: 0, answered: 0 };
       }

       // Increment the relevant metric for this week
       if (metric === "calls") {
        groupedDataByWeek[week].calls += parseInt(res["daily"][key]);
       }

       if (metric === "answered") {
        groupedDataByWeek[week].answered += parseInt(res["daily"][key]);
       }
      }
     });

     Object.keys(res["daily*"]).forEach(key => {
      let [date, , , metric] = key.split(":");
      let week = this.getWeekNumber(date); // Extract the week number from the date (YYYYMMDD)

      if (!groupedDataByWeekCons[week]) {
       groupedDataByWeekCons[week] = { calls: 0, answered: 0 };
      }

      // Increment the relevant metric for this week
      if (metric === "calls") {
       groupedDataByWeekCons[week].calls += parseInt(res["daily*"][key]);
      }

      if (metric === "answered") {
       groupedDataByWeekCons[week].answered += parseInt(res["daily*"][key]);
      }
     });

     // Prepare the data for the column chart
     let weeks = Object.keys(groupedDataByWeek);

     weeks.sort(); // Sort weeks in chronological order

     obj = {};
     objCons = {};
     dataObjAns = {};
     dataObjAnsCons = {};
     dataObjUn = {};
     dataObjUnCons = {};
     dataObjAns["data"] = [];
     dataObjUn["data"] = [];
     dataObjAnsCons["data"] = [];
     dataObjUnCons["data"] = [];
     obj["labels"] = [];
     objCons["labels"] = [];
     weeks.forEach((week, weekIdx) => {
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"].splice(weekIdx, 1, groupedDataByWeek[week].answered);
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"].splice(
       weekIdx,
       1,
       groupedDataByWeekCons[week].answered
      );

      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"].splice(
       weekIdx,
       1,
       groupedDataByWeek[week].calls - groupedDataByWeek[week].answered
      );
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"].splice(
       weekIdx,
       1,
       groupedDataByWeekCons[week].calls - groupedDataByWeekCons[week].answered
      );

      let elementName = "";
      elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];

      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      obj["labels"].splice(weekIdx, 1, week);

      objCons["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["labels"].splice(weekIdx, 1, week);
     });
     obj["data"] = [dataObjAns, dataObjUn];
     objCons["data"] = [dataObjAnsCons, dataObjUnCons];
     this.extensions.splice(arrIndex, 1, obj["name"]);
     this.dataColumnChartWeekNumber.splice(arrIndex, 1, obj);
     this.dataColumnChartConsWeekNumber.splice(arrIndex, 1, objCons);
    }
    this.$emit("setLoading", false);
   }
   console.log(
    "getDataFromElementForColumnWeekNumber ~ this.dataColumnChartConsWeekNumber:",
    this.dataColumnChartConsWeekNumber
   );
  },
  async getTimeLabelRange(arr) {
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let start = ("0" + String(sortedArray[0])).slic;
   let end = ("0" + String(sortedArray[arr.length - 1])).slic;
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeStartAndEnd(start, end) {
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourly(arr) {
   let hourlyRange = [];
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let end = "";
   let start = ("0" + String(sortedArray[0]) + "00").slice(0, 4);
   if (String(sortedArray[arr.length - 1]).length > 1) {
    end = (String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   } else {
    end = ("0" + String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   }
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourlyStartAndEnd(start, end) {
   let hourlyRange = [];
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  filterNoData(data) {
   return data?.filter(res => {
    return res.data[0]?.data?.length > 0 || res.data[1]?.data?.length > 0;
   });
  },
 },
 mounted() {
  // if (!this.periodSelector) {
  //  this.periodSelector = this.currentMonthAndYearForPbx;
  // }
  // if (this.pbxPeriodSelected) this.periodSelector = this.pbxPeriodSelected;

  if (this.$route.query.tableElement && this.$route.query.period) {
   this.isQueryParam = true;
  }

  if (this.pbxElementType !== this.urlType)
   this.$store.dispatch("pbxElements", null);

  this.getButtonSelectedFromQueryPeriod();
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostContext",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
   "pbxMap",
  ]),

  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
    .format(month)
    .slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
 watch: {
  is_daily: function (val) {
   if (val) {
    this.getDataFromElementForColumnDaily();
   } else {
    this.getDataFromElementForColumn();
   }
  },
  selected_answered_type: function (val) {
   switch (val) {
    case "hour":
     this.getDataFromElementForColumn();
    case "day":
     this.getDataFromElementForColumnDaily();
    case "week":
     this.getDataFromElementForColumnWeek();
    case "weekNumber":
     this.getDataFromElementForColumnWeekNumber();
    case "month":
     this.getDataFromElementForColumnMonth();
    default:
     return "";
   }
  },
  // periodSelector: function (val) {
  //  this.getDataFromElementForColumn();
  // },
  timeSelected: function (val) {
   this.getDataFromElementForColumn(true);
  },
  urlType: function (val) {
   console.log("changed type");
   this.$store.dispatch("pbxElements", null);
   this.extensions = [];
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
